//Images
import ic_no_balance from './assets/images/ic_no_balance@3x.png';
import ic_success from './assets/images/ic_success@3x.png';
import ic_not_found from './assets/images/ic_not_found@3x.png';

import { toChecksumAddress } from 'web3-utils';
import Config from './Config';

export const Images = {
    ic_no_balance,
    ic_success,
    ic_not_found
}

export type BankNoteResponse = {
    status: number;
    data: any
}

export enum BankNote {
    SUCCESS,
    NO_BALANCE,
    NOT_FOUND
}

export enum Environment {
    DEMO = "development",
    PRODUCTION = "production"
}

export const ApiUtils = {
    getVersion: () => {
        var url = window.location.href;
        var urlParts = url.replace('http://', '').replace('https://', '').replace('c/', '').split(/[/?#]/);
        if (urlParts.length >= 2) {
            return urlParts[1];
        } else {
            return "";
        }
    },

    getWalletAddress: () => {
        var url = window.location.href;
        var urlParts = url.replace('http://', '').replace('https://', '').replace('c/', '').split(/[/?#]/);
        if (urlParts.length >= 3) {
            return urlParts[2];
        } else {
            return "";
        }
    },

    getBankNoteDetails: async (version: string, walletAddress: string): Promise<BankNoteResponse> => {

        // https://demo.trustwise.io/api/v1/cbdc/banknotes/0x8d59f4E1d026C1568906eea6030Eb09BC2ee25ca/
        let domainPrefix = "demo.";
        // if(Config.environment === Environment.PRODUCTION) {
        //     domainPrefix = "";
        // }

        const checksumWalletAddress = toChecksumAddress(`0x${walletAddress}`);
        const url = `https://${domainPrefix}${Config.domain}/api/v${version}/cbdc/banknotes/${checksumWalletAddress}`;

        console.log("Environment: ", Config.environment);
        console.log("Version: ", version);
        console.log("Wallet Address: ", walletAddress);
        console.log("URL: ", url);

        let response = await fetch(url);

        try {
            let json = await response.json();
            let finalResponse: BankNoteResponse = {
                status: response.status,
                data: json
            };

            return finalResponse;
        } catch (error) {
            let finalResponse: BankNoteResponse = {
                status: response.status,
                data: error
            };

            return finalResponse;
        }
    }
}