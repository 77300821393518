import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    html, body {
        height: 100%;
        width: 100%;
    }

    body {
        margin: 0;
        padding: 0;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    * {
        font-family: 'Roboto', sans-serif;
    }
`

export const Wrapper = styled.div`
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    > div {
        display:block;
        width: 100%;
    }
/* 
    .icon {
        font-weight: 100;
        font-size: 73px;
    }

    .success-icon {
        color: #0ACF83;
    }

    .no-balance-icon {
        color: #C83B4C;
    }

    .not-found-icon {
        color: #1B71FA;
    } */
`

export const TitleLabel = styled.span`
    position: absolute;
    top: 45px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    color: #333333;
`

export const LoadingLabel = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
`

export const CodeBalance = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin-bottom: 32px;
`

export const BalanceMessage = styled.span`
    margin-top: 27px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`
export const BoldText = styled.span`
    font-family: Roboto;
    margin-left: 4px;
    margin-right: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`

export const Message = styled.span`
    margin-top: 68px;
    margin-bottom: 125px;
    word-wrap: break-word;
    width: 250px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`

export const FooterText = styled.span`
    position:absolute;
    bottom:49px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    color: #666666;
`
export const FooterUrl = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    text-decoration: underline;
    align-items: center;
    text-align: center;
    color: #666666;
`