import React, { useState, useEffect } from 'react';
import {
  BalanceMessage,
  BoldText,
  CodeBalance,
  FooterText,
  GlobalStyle,
  LoadingLabel,
  Message,
  TitleLabel,
  Wrapper,
} from "./assets/styles/App.styles";

import {
  ApiUtils,
  BankNote,
  BankNoteResponse,
  Images,
} from "./Utils";

const App = () => {

  const [loading, setLoading] = useState(true);

  const [balanceStatus, setBalanceStatus] = useState(BankNote.SUCCESS);
  const [headerName, setHeaderName] = useState("");
  const [denomination, setDenomination] = useState(0);
  const [code, setCode] = useState("");


  useEffect(() => {

    setLoading(true);
    let version = ApiUtils.getVersion();
    let walletAddress = ApiUtils.getWalletAddress();

    (async () => {
      let bankNote: BankNoteResponse = await ApiUtils.getBankNoteDetails(version, walletAddress);
      console.log("bankNote: ", bankNote);

      switch (bankNote.status) {
        case 200:
          const { denomination, name, balance, code } = bankNote.data;
          setHeaderName(name);
          setCode(code);
          setDenomination(denomination);

          if (balance === denomination) {
            setBalanceStatus(BankNote.SUCCESS);
          } else if (balance === 0) {
            setBalanceStatus(BankNote.NO_BALANCE);
          }

          break;
        case 404:
        case 500:
          setBalanceStatus(BankNote.NOT_FOUND);
          break;

        default:
          break;
      }

      setLoading(false);
    })();
  }, [])

  const getStatusIcon = (balanceStatus: BankNote) => {
    if (balanceStatus === BankNote.SUCCESS) {
      return <img src={Images.ic_success} alt="" width="88px" height="88px" />
    } else if (balanceStatus === BankNote.NO_BALANCE) {
      return <img src={Images.ic_no_balance} alt="" width="88px" height="88px" />
    } else {
      return <img src={Images.ic_not_found} alt="" width="88px" height="88px" />
    }
  }

  const getBankBalanceMessage = (balanceStatus: BankNote) => {
    if (balanceStatus === BankNote.SUCCESS) {
      return <BalanceMessage>Balance is available in full.</BalanceMessage>
    } else if (balanceStatus === BankNote.NO_BALANCE) {
      return <BalanceMessage>Balance is <BoldText>NOT</BoldText> available.</BalanceMessage>
    } else {
      return <BalanceMessage>Banknote not found.</BalanceMessage>
    }
  }

  return (
    <>
      <GlobalStyle />
      <Wrapper>

        {loading && <LoadingLabel>Loading...</LoadingLabel>}

        {!loading && <>
          <TitleLabel>{headerName}</TitleLabel>
          {balanceStatus !== BankNote.NOT_FOUND && <CodeBalance>{denomination} {code}</CodeBalance>}
          {getStatusIcon(balanceStatus)}
          {getBankBalanceMessage(balanceStatus)}
          {balanceStatus !== BankNote.NOT_FOUND && <>
            <Message>The Utopian Dual is an imaginary currency to demonstrate the functionality of a CBDC and a connected banknote.</Message>
            <FooterText>
              © Orell Füssli AG | trustwise.io ag | TrustNoteD AG
            </FooterText>
          </>}
        </>}
      </Wrapper>
    </>
  );
}

export default App;


